<template>
    <div class="article-box" v-loading="loading">
        <!-- 导航 -->
        <div class="page-nav clearfix">
            <ul>
                <li class = "nav-item">
                    会员管理&nbsp;&nbsp;/
                </li>
                <li>
                    <router-link class="nav-item" to="/institution/member/change">
                        &nbsp;&nbsp;单位代表变更&nbsp;&nbsp;/
                    </router-link>
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;查看详情
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">单位代表变更详情</div>
        </div>
        <div style="padding:10px">
            <el-descriptions class="margin-top" title="变更单位代表信息" :column="3"  border>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">单位代表姓名</template>{{detailInfo.name || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">性别</template>{{sexArr[detailInfo.sex ] || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">出生年月</template>{{detailInfo.birthday || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">民族</template>{{detailInfo.national || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">籍贯</template>{{detailInfo.nativePlace || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">政治面貌</template>{{detailInfo.politicsStatus  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职务</template>{{detailInfo.position  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职称</template>{{detailInfo.title || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">学历</template>{{detailInfo.degree || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">学位</template>{{detailInfo.education || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">手机</template>{{detailInfo.phone || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">办公电话</template>{{detailInfo.telephone || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">微信</template>{{detailInfo.wechat || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">Emai</template>{{detailInfo.email || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">传真</template>{{detailInfo.fax || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">邮政编码</template>{{detailInfo.zipCode || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="2">
                    <template slot="label">通讯地址</template>{{detailInfo.mailingAddress || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="2">
                    <template slot="label">复函</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.replyInfo" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="个人简历" :column="3"  border>
                <div  v-for="(item,index) in detailInfo.memberForResumes" :key="'user'+index">
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">起止年月</template>{{item.time || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">单位</template>{{item.unit  || '-'  }}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">职务</template>{{item.position  || '-'  }}
                    </el-descriptions-item>
                </div>
                
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">联系人姓名</template>{{detailInfo.contactName  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职务</template>{{detailInfo.contactPosition  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">传真</template>{{detailInfo.contactFax  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">手机</template>{{detailInfo.contactPhone  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">办公电话</template>{{detailInfo.contactTelephone  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">微信</template>{{detailInfo.contactWechat  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">Email</template>{{detailInfo.contactEmail  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="2">
                    <template slot="label">邮政编码</template>{{detailInfo.contactZipCode  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">通讯地址</template>{{detailInfo.contactMailingAddress  || '-' }}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :span="3">
                    <template slot="label">单位代表和联系人登记加盖公章表</template>
                    <div class="file-show-list">
                        <div v-for="(f) in detailInfo.unitReprsts" :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>
<script>
import { MemberUpdateDetail} from "@/http/api/InitiationApi";
import { FileDownload } from '@/http/api/FileApi';
export default {
  components: { },
  data () {
    return {
        uid: '',
        loading: false,
        sexArr: ['', '男', '女'],
        detailInfo:{
            memberForResumes: [],
            unitReprsts: [],
            replyInfo: []
        }
    }
  },
  created () {
    this.uid = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail () {
        this.loading = true
        MemberUpdateDetail({id: this.uid}).then(resp => {
            this.loading = false
            if (resp.code == '000') {
                this.detailInfo = {...resp.result};
                this.detailInfo.memberForResumes = resp.result.memberForResumes || [];
                this.detailInfo.unitReprsts = resp.result.unitReprsts || [];
                this.detailInfo.replyInfo = resp.result.replyInfo || [];
            }
        })
    },
    download (path,name) {
        FileDownload(path, name)
    },
   
  }
}
</script>
<style lang="less" scoped>
.my-label {
    width: 150px;
}
/deep/.el-descriptions__header {
    margin-top: 20px;
}

/deep/.my-label {
    width: 150px;
}
/deep/.el-descriptions__header {
    margin-top: 20px;
}
/deep/.no-my-label {
    width: 0;
    padding: 0!important;
    border: none;
}
</style>
